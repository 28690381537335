<template>
  <v-row no-gutters class='my-3'>
    <v-toolbar flat>
      <v-row v-if='filter === filters.CUSTOM' class='ml-1'>
        <v-text-field
          v-model='date[0]'
          type='date'
          :label='$t("INITIAL_DATE")'
          hide-details
          class='mr-5'
          @change='$emit("load-data", date)'
        />
        <v-text-field
          v-model='date[1]'
          type='date'
          :label='$t("END_DATE")'
          hide-details
          class='ml-5'
          @change='$emit("load-data", date)'
        />
      </v-row>
      <v-spacer />

      <v-btn-toggle v-model='filter' color='secondary' mandatory @change='changeFilter'>
        <v-btn>
          {{ $t('TODAY') }}
        </v-btn>
        <v-btn>
          {{ $t('LAST_X_DAYS', { value: 3 }) }}
        </v-btn>
        <v-btn>
          {{ $t('LAST_X_DAYS', { value: 7 }) }}
        </v-btn>
        <v-btn>
          {{ $t('CUSTOM') }}
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
  </v-row>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'PeriodPickerToolbar',
    data: function () {
      return {
        filters: {
          TODAY: 0,
          LAST_3_DAYS: 1,
          LAST_7_DAYS: 2,
          CUSTOM: 3,
        },
        filter: 0,
        date: [],
      };
    },
    mounted: function () {
      this.clearRangeDateFilter();
    },
    methods: {
      getRecordsNotFound: async function () {
        try {
          const { data } = await axios({
            url: '/record-reusage/not-found',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            params: {
              initialDate: this.date[0],
              endDate: this.date[1],
            },
          });

          this.notFoundList = data.recordsNotFound;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      formattedDateAndTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy HH:mm:ss.SSS', { locale: ptBr });
      },
      formattedDate: function (value) {
        return value && format(parseISO(value), 'dd MMM', { locale: ptBr });
      },
      clearRangeDateFilter: function () {
        const currentDate = new Date();
        const previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - 0);

        this.date = [
          format(previousDate, 'yyyy-MM-dd'),
          format(currentDate, 'yyyy-MM-dd'),
        ];

        this.$nextTick(() => {
          this.$emit('load-data', this.date);
        });
      },
      changeFilter: function () {
        if (this.filter !== this.filters.CUSTOM) {
          let value;

          if (this.filter === this.filters.TODAY) {
            value = 0; // dia atual
          } else if (this.filter === this.filters.LAST_3_DAYS) {
            value = 3; // 3 dias
          } else {
            value = 7; // 7 dias
          }

          const currentDate = new Date();
          const previousDate = new Date();
          previousDate.setDate(previousDate.getDate() - value);

          this.date = [
            format(previousDate, 'yyyy-MM-dd'),
            format(currentDate, 'yyyy-MM-dd'),
          ];

          this.$nextTick(() => {
            this.$emit('load-data', this.date);
          });
        }
      },
    },
  };
</script>
